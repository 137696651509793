<template>
  <div class="homepage">
    <!-- 你的资料 -->
    <div class="homepage-youName">
      <div class="homepage-youName-left">
        <div class="homepage-youName-left-top">
          <div class="homepage-youName-left-top-photo">
            <img alt="" v-if="indexData.user_info.avatar" v-lazy="indexData.user_info.avatar"
                 :data-src="indexData.user_info.avatar"/>
            <strong>{{ indexData.user_info.name }}</strong>
            <el-tag size="mini" type="primary" v-if="indexData.user_info.type == 1">注册用户</el-tag>
            <el-tag size="mini" type="primary" v-else-if="indexData.user_info.type == 2">匿名用户</el-tag>
            <el-tag size="mini" type="primary" v-else-if="indexData.user_info.type == 3">快速登录用户</el-tag>
          </div>
          <div class="homepage-youName-left-top-tag">
            <el-tag type="primary" effect="plain">
              {{ identity_name }}
            </el-tag>
          </div>

          <div class="user-desc">
            {{indexData.user_info.intro}}
          </div>
        </div>
        <div class="homepage-youName-left-bottom">
          <div class="homepage-youName-left-bottom-data">
            <el-progress :stroke-width="10" :color="'#00a2e6'" :percentage="percent" />
            <el-button type="primary" size="mini" @click="$router.push('/home/information/base')">完善资料</el-button>
            <span class="desc">完善资料获爪币，好礼等你来兑!</span>
          </div>
        </div>
      </div>
      <div class="homepage-youName-right">
        <PersonAtt :authInfo="indexData.auth_info" class="personAtt"></PersonAtt>
      </div>
    </div>
    <!-- 完善您的技能 -->
    <div class="homepage-perfect-task" v-show="false">
      <div class="homepage-perfect-task-header">
        <div class="homepage-perfect-task-header-font">
          <p>
            <strong>完善资料</strong>
            <img src="@/icons/svg/person/提示16X16.svg" alt=""/>
            <span>提示：在开始使用之前，请您完善基本资料</span>
          </p>
        </div>
      </div>
      <div class="homepage-perfect-task-hour">
        <div class="homepage-perfect-task-hour1">
          <p>
            <strong>每小时收费</strong>
            <span>为您的个人资料输入每小时费率以对该项目投标，这不会影响您对特定项目的投标。</span>
          </p>
          <br/>
          <el-input type="number" v-model="taskForm.hour" size="mini" style="width: 120px;"></el-input>
          <span style="margin-left: 10px">元/小时</span>
          <span class="user-info-name-border-right-hour-right">
						如果预估自己的薪水？
						<a href="#">点击查看</a>
					</span>
        </div>
        <div class="homepage-perfect-task-select">
          <div class="user-info-name-border-right-task">
            <p>
              <strong>选择技能</strong>
              <span style="color: #575757; font-size: 12px">最多选择5项技能</span>
            </p>
            <div class="skill-select">
              <!-- 最多可以选5个技能 -->
              <el-select
                  v-model="taskForm.skills"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="点击输入技能"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  :multiple-limit="5"
                  style="width: 620px;"
              >
                <el-option v-for="item in skills" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
            <p class="skill-p">
              推荐技能：
              <span v-for="reskill in recommand_skills" :key="reskill.id">
								<el-link @click="clickAppendSkill(reskill)" type="primary">{{ reskill.name }}</el-link>
							</span>
            </p>
            <b>最多选择5项技能</b>
            <div class="homepage-perfect-task-select-button">
              <button>提交</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 入住平台和账户概览 -->
    <div class="homepage-checkIn">
      <div class="homepage-checkIn-date">
        <div class="homepage-checkIn-date-border">
          <div class="homepage-checkIn-date-border1">
            <span>入驻平台</span>
            <strong>{{ indexData.counter.settled_days ? indexData.counter.settled_days : 0 }}</strong>
            <b>天</b>
          </div>
          <div class="homepage-checkIn-date-border2">
            <span>共承接项目</span>
            <strong>{{ indexData.counter.undertake_work_num ? indexData.counter.undertake_work_num : 0 }}</strong>
            <b>个</b>
          </div>
          <div class="homepage-checkIn-date-border3">
            <span>发布服务</span>
            <strong>{{ indexData.counter.publish_service_num ? indexData.counter.publish_service_num : 0 }}</strong>
            <b>个</b>
          </div>
          <div class="homepage-checkIn-date-border4">
            <span>累计收益</span>
            <strong>{{ indexData.counter.acc_income ? indexData.counter.acc_income.split(".")[0] : 0 }}</strong>
            <b>.{{ indexData.counter.acc_income ? indexData.counter.acc_income.split(".")[1] : '00' }}元</b>
          </div>
          <div class="homepage-checkIn-date-border5">
            <span>收到评价</span>
            <strong>{{ indexData.counter.review_count ? indexData.counter.review_count : 0 }}</strong>
            <b>次</b>
          </div>
        </div>
      </div>
      <!-- 账户概览 -->
      <div class="homepage-checkIn-account">
        <div class="homepage-checkIn-account-header">
          <div class="homepage-checkIn-account-header-left"><strong>账户概览</strong></div>
          <div class="homepage-checkIn-account-header-right"><a href="#">资金明细</a></div>
        </div>
        <div class="homepage-checkIn-account-header-fonts">
          <div class="homepage-checkIn-account-header-fonts1">
            <span>昨日收益</span>
            <br/>
            <p>
              <!-- <strong>{{ indexData.wallte.yesterday_income?indexData.wallte.yesterday_income:0 }}</strong>
              <b>.00</b> -->
              <strong>{{
                  indexData.wallte.yesterday_income ? indexData.wallte.yesterday_income.split(".")[0] : '0'
                }}</strong>
              <b>.{{ indexData.wallte.yesterday_income ? indexData.wallte.yesterday_income.split(".")[1] : '00' }}</b>
              <span>元</span>
            </p>
            <button>昨日无变化</button>
          </div>
          <div class="homepage-checkIn-account-header-fonts2">
            <span>账户余额</span>
            <br/>
            <p>
              <!-- <strong>{{ indexData.wallte.wait_grant?indexData.wallte.wait_grant:0 }}</strong>
              <b>.00</b> -->
              <strong>{{ indexData.wallte.wait_grant ? indexData.wallte.wait_grant.split(".")[0] : 0 }}</strong>
              <b>.{{ indexData.wallte.wait_grant ? indexData.wallte.wait_grant.split(".")[1] : '00' }}</b>
              <span>元</span>
            </p>
            <button>包含已冻结资金</button>
          </div>
          <div class="homepage-checkIn-account-header-fonts3">
            <span>可提现</span>
            <br/>
            <p>
              <!-- <strong>{{ indexData.wallte.can_withdraw?indexData.wallte.can_withdraw:0 }}</strong>
              <b>.00</b> -->
              <strong>{{ indexData.wallte.can_withdraw ? indexData.wallte.can_withdraw.split(".")[0] : 0 }}</strong>
              <b>.{{ indexData.wallte.can_withdraw ? indexData.wallte.can_withdraw.split(".")[1] : '00' }}</b>
              <span>元</span>
            </p>
            <el-tooltip class="item" effect="dark" content="提现功能暂未开放" placement="bottom">
              <button>申请提现</button>
            </el-tooltip>
          </div>
        </div>
      </div>

      <!-- 项目 -->
      <div class="homepage-checkIn-account-program">
        <div class="homepage-checkIn-account-program-header"><strong>项目</strong></div>
        <div class="homepage-checkIn-account-program-center">
          <div class="homepage-checkIn-account-program-center1">
            <span>已完成项目</span>
            <br/>
            <p>
              <strong>{{ indexData.project.finish_project_num ? indexData.project.finish_project_num : 0 }}</strong>
              <span>个</span>
            </p>
          </div>
          <div class="homepage-checkIn-account-program-center2">
            <span>进行中的项目</span>
            <br/>
            <p>
              <strong style="color">{{
                  indexData.project.doing_project_num ? indexData.project.doing_project_num : 0
                }}</strong>
              <span>个</span>
            </p>
          </div>
        </div>
        <div class="homepage-checkIn-account-program-task-title" v-if="indexData.project.project_list.length > 0">
          <div class="homepage-checkIn-account-program-task-title-border1"
               v-for="(t, index) in indexData.project.project_list" :key="index">
            <div class="homepage-checkIn-account-program-task-title-border1-left">
              <span>{{ t }}</span>
            </div>
            <div class="homepage-checkIn-account-program-task-title-border1-right"><span>进入</span></div>
          </div>
        </div>
        <div class="homepage-checkIn-account-program-button" v-if="indexData.project.project_list.length > 0">
          <button>查看全部项目</button>
        </div>
      </div>
    </div>
    <!-- 推荐 -->
    <div class="homepage-recommend" v-show="false">
      <div class="homepage-recommend-top">
        <div class="homepage-recommend-top-left"><strong>为您推荐以下平台活动</strong></div>
        <div class="homepage-recommend-top-right"><b>更多></b></div>
      </div>
      <div class="homepage-recommend-bottom">
        <div class="homepage-recommend-bottom-left">
          <div class="homepage-recommend-bottom-left1">
            <Isvg icon-class="questionnaire-survey"/>
          </div>
          <div class="homepage-recommend-bottom-left2">
            <div class="homepage-recommend-bottom-left2-top"><h2>平台问卷大调查</h2></div>
            <div class="homepage-recommend-bottom-left2-bottom"><span>爪币赚的飞起来，动起你的小手指</span></div>
          </div>
        </div>
        <div class="homepage-recommend-bottom-right">
          <div class="homepage-recommend-bottom-left1">
            <Isvg icon-class="questionnaire-survey"/>
          </div>
          <div class="homepage-recommend-bottom-left2">
            <div class="homepage-recommend-bottom-left2-top"><h2>平台问卷大调查</h2></div>
            <div class="homepage-recommend-bottom-left2-bottom"><span>爪币赚的飞起来，动起你的小手指</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonAtt from '@/components/PersonAtt';
import {getIndex} from '@/api/user';

export default {
  name: 'homepage',
  components: {
    PersonAtt
  },
  data() {
    return {
      currentUser: null,
      inside: 50,
      taskForm: {
        hour: '', //小时数
        skill: [] //技能
      },
      skills: [],
      loading: false,
      recommand_skills: [],
      indexData: {
        user_info: {user: {}},
        project: {project_list: []},
        counter: {},
        auth_info: {},
        wallte: {}
      },
      identity_name: '',
      percent: 0
    };
  },
  created() {
    this.loadIndex();
    this.currentUser = this.$store.getters["useUserStore/getUserInfo"]
    let identity = localStorage.getItem('identity_' + this.currentUser.id)
    if (identity == 1) {
      this.identity_name = '灵工'
    } else {
      this.identity_name = '雇主'
    }
  },
  mounted() {
    let that = this
    window.addEventListener("setItemEvent", function (e) {
      if (e.newValue == 1) {
        that.identity_name = '灵工'
      } else {
        that.identity_name = '雇主'
      }
    })
    window.removeEventListener("setItemEvent", function (e) {

    })
  },
  methods: {
    remoteMethod() {
    },
    async loadIndex() {
      const res = await getIndex({}, this.$store.getters["useUserStore/getToken"]);
      if (res.statusCode == 200) {
        this.indexData = res.data;
        this.indexData.user_info = res.data.user_info.user
        this.percent = this.indexData.user_info.percent? parseInt( this.indexData.user_info.percent ) : 0
      }
    }
  }
};
</script>

<style lang="less">
.homepage {
  width: 940px;

  .el-tag {
    cursor: pointer;
  }
  //你的资料
  .homepage-youName {
    display: flex;
    justify-content: space-between;

    .homepage-youName-left {
      width: 620px;
      height: 285px;
      background: #ffffff;

      .homepage-youName-left-top {
        width: 540px;
        margin: 0 auto;
        height: 100px;
        border-bottom: 1px solid #b5b5b5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .homepage-youName-left-top-photo {
          width: 70%;
          height: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }

          strong {
            font-size: 18px;
            margin: 0 20px;
          }
        }

        .homepage-youName-left-top-tag {
          width: 30%;
          color: #00a2e6 !important;
          border-color: #00a2e6;
          display: flex;
          justify-content: flex-end;
        }

        .user-desc {
          font-size: 12px;
        }
      }

      .homepage-youName-left-bottom {
        width: 540px;
        margin: 0 auto;

        .homepage-youName-left-bottom-data {
          margin-top: 40px;

          p {
            font-size: 14px;
            color: #575757;
          }

          .el-progress {
            margin: 10px 0 20px 0;
          }

          .el-progress__text {
            font-size: 14px !important;
            margin-left: 10px;
          }

          .el-button {
            background-color: #00a2e6;
            border-radius: 20px !important;
          }

          .desc {
            margin-left: 20px;
            font-size: 14px;
          }
        }
      }
    }

    .homepage-youName-right {
      width: 300px;
      height: 285px;
      background-color: #ffffff;
      display: flex;
      margin-left: 20px;

      .personAtt {
        margin-left: 40px;

        ul {
          width: 260px;
          margin-top: 40px;
        }

        li {
          margin-bottom: 30px;
        }
      }
    }

    .homepage-youName-left:hover{
      box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);/*盒子阴影*/
      transition: all 0.5s;/*持续时间*/
    }
    .homepage-youName-right:hover{
      box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);/*盒子阴影*/
      transition: all 0.5s;/*持续时间*/
    }
  }

  //完善资料
  .homepage-perfect-task {
    width: 940px;
    height: 466px;
    background: #ffffff;
    margin-top: 20px;

    .homepage-perfect-task-header {
      height: 77px;
      width: 860px;
      margin: 0 auto;
      border-bottom: 1px solid #b5b5b5;

      .homepage-perfect-task-header-font {
        position: relative;
        top: 40px;

        p {
          strong {
            font-size: 18px;
          }

          img {
            width: 16px;
            height: 16px;
            margin: 0 10px 0 20px;
          }

          span {
            font-size: 12px;
            color: #575757;
          }
        }
      }
    }

    //小时数
    .homepage-perfect-task-hour {
      width: 860px;
      margin: 0 auto;
      margin-top: 20px;

      span {
        font-size: 12px;
        color: #575757;
      }

      //每小时收费
      .homepage-perfect-task-hour1 {
        p {
          strong {
            font-size: 18px;
            margin-right: 10px;
          }
        }

        i {
          position: relative;
          left: 25px;
          z-index: 999;
        }

        .user-info-name-border-right-hour-right {
          margin-left: 20px;

          a {
            color: #00a2e6;
          }
        }
      }

      //选择技能
      .homepage-perfect-task-select {
        margin-top: 40px;

        p {
          margin-bottom: 20px;

          strong {
            font-size: 18px;
            margin-right: 10px;
          }
        }

        .skill-p {
          margin-top: 20px;
          font-size: 14px;

          span {
            color: #575757;
            margin-left: 20px;
          }
        }

        b {
          font-size: 12px;
          display: block;
          margin-top: 20px;
        }

        .homepage-perfect-task-select-button {
          width: 200px;
          margin: 0 auto;
          margin-top: 40px;
          padding-bottom: 40px;

          button {
            width: 200px;
            line-height: 36px;
            background-color: #00a2e6;
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
  }

  //入住时间和账户概览
  .homepage-checkIn {
    margin-top: 20px;

    .homepage-checkIn-date {
      width: 100%;
      height: 190px;
      background: #ffffff;
      display: flex;
      /*  justify-content: center; */

      .homepage-checkIn-date-border {
        width: 860px;
        margin: 0 auto;
        display: flex;
        margin-top: 60px;
        justify-content: space-between;

        span,
        b {
          font-size: 14px;
          color: #575757;
        }

        span {
          display: block;
          margin-bottom: 20px;
        }

        strong {
          font-size: 36px;
        }

        b {
          margin-left: 5px;
        }

        .homepage-checkIn-date-border1,
        .homepage-checkIn-date-border2,
        .homepage-checkIn-date-border3,
        .homepage-checkIn-date-border4,
        .homepage-checkIn-date-border5 {
          width: 160px;
          text-align: center;
        }
      }
    }

    .homepage-checkIn-date:hover {
      box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);
      transition: all 0.5s;
    }

    //账户概览
    .homepage-checkIn-account {
      width: 940px;
      min-height: 300px;
      background-color: #fff;
      margin-top: 20px;

      .homepage-checkIn-account-header {
        height: 77px;
        width: 860px;
        margin: 0 auto;
        border-bottom: 1px solid #b5b5b5;
        display: flex;
        justify-content: space-between;

        .homepage-checkIn-account-header-left,
        .homepage-checkIn-account-header-right {
          margin-top: 40px;

          strong {
            font-size: 18px;
          }

          a {
            font-size: 12px;
            color: #00a2e6;
          }
        }
      }

      .homepage-checkIn-account-header-fonts {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        margin-left: 40px;
        margin-right: 40px;

        span {
          font-size: 14px;
          color: #575757;
          margin-left: 5px;
        }

        strong {
          font-size: 36px;
        }

        button {
          padding: 5px 15px;
          font-size: 14px;
          color: #575757;
          border-radius: 12px;
        }

        .homepage-checkIn-account-header-fonts1,
        .homepage-checkIn-account-header-fonts2,
        .homepage-checkIn-account-header-fonts3 {
          width: 270px;
          text-align: center;

          p {
            margin-bottom: 20px;
            margin-top: 30px;
          }
        }

        .homepage-checkIn-account-header-fonts2 {
        }

        .homepage-checkIn-account-header-fonts3 {
          button {
            background-color: #00a2e6;
            color: #fff;
          }
        }
      }
    }

    .homepage-checkIn-account:hover {
      box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);
      transition: all 0.5s;
    }

    .homepage-checkIn-account-program {
      margin-top: 20px;
      min-height: 250px;
      background-color: #fff;
      .homepage-checkIn-account-program-header {
        height: 95px;
        width: 860px;
        margin: 0 auto;
        border-bottom: 1px solid #b5b5b5;

        strong {
          top: 60px;
          position: relative;
          font-size: 18px;
        }
      }

      .homepage-checkIn-account-program-center {
        width: 500px;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 14px;
          color: #575757;
          margin-left: 5px;
        }

        strong {
          font-size: 36px;
        }

        .homepage-checkIn-account-program-center1,
        .homepage-checkIn-account-program-center2 {
          width: 200px;
          text-align: center;

          p {
            margin-top: 30px;
          }
        }
      }

      .homepage-checkIn-account-program-task-title {
        margin-left: 40px;
        height: 128px;
        margin-top: 40px;

        .homepage-checkIn-account-program-task-title-border1 {
          display: flex;
          line-height: 36px;
          margin-bottom: 10px;

          span {
            font-size: 14px;
            color: #575757;
          }

          .homepage-checkIn-account-program-task-title-border1-left {
            width: 720px;
            height: 36px;
            background-color: #f8f8f8;
            letter-spacing: 2px;

            span {
              margin-left: 20px;
              display: block;
              width: 600px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .homepage-checkIn-account-program-task-title-border1-right {
            span {
              margin-left: 70px;
            }
          }
        }
      }

      .homepage-checkIn-account-program-button {
        width: 200px;
        margin: 0 auto;
        margin-top: 40px;

        button {
          width: 200px;
          height: 36px;
          font-size: 18px;
          color: #fff;
          background-color: #00a2e6;
        }
      }
    }

    .homepage-checkIn-account-program:hover {
      box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);
      transition: all 0.5s;
    }
  }

  //推荐平台活动
  .homepage-recommend {
    width: 940px;
    height: 260px;
    background-color: #fff;
    margin-top: 20px;

    .homepage-recommend-top {
      width: 860px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .homepage-recommend-top-left,
      .homepage-recommend-top-right {
        margin-top: 40px;
      }

      strong {
        font-size: 18px;
      }

      b {
        font-size: 12px;
        color: #00a2e6;
      }
    }

    .homepage-recommend-bottom {
      margin: 0 auto;
      margin-top: 40px;
      width: 860px;
      display: flex;
      justify-content: space-between;

      .homepage-recommend-bottom-left,
      .homepage-recommend-bottom-right {
        width: 420px;
        height: 120px;
        background-color: #f2fbff;
        display: flex;
        align-items: center;

        .homepage-recommend-bottom-left1 {
          margin-left: 20px;
          font-size: 54px;
        }

        .homepage-recommend-bottom-left2 {
          display: flex;
          height: 58px;
          flex-direction: column;
          margin-left: 20px;

          .homepage-recommend-bottom-left2-top {
            margin-bottom: 15px;

            h2 {
              font-size: 24px;
            }
          }

          .homepage-recommend-bottom-left2-bottom {
            span {
              font-size: 12px;
              color: #575757;
            }
          }
        }
      }
    }
  }
}

.el-input__inner {
  border-radius: 0;
  height: 36px;
}

.el-input {
  margin-left: 0px;
}
</style>
